<template>
 <div class="overflow-hidden bg-white ml-20 py-24 sm:pt-28 sm:pb-32 h-full">
  <div
   class="mx-auto xl:max-w-7xl lg:max-w-5xl md:max-w-5xl sm:max-w-2xl px-4 sm:px-6 lg:px-8">
   <div
    class="mx-auto sm:grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
    <div class="lg:pr-8 lg:pt-4">
     <div class="lg:max-w-lg">
      <h2 class="text-base font-semibold leading-7 encom_primary_text">
       {{ $t("Expert Statistics") }}
      </h2>
      <p
       class="mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
       <!-- {{ $t("secondPrimaryTextNoAuthPage") }} -->
       Améliorez la satisfaction client et la productivité des équipes
      </p>
      <p class="mt-6 text-base sm:text-lg leading-6 sm:leading-8 text-gray-600">
       <!-- {{ $t("paragraphNoAuthPage") }}. -->
       Analysez vos données par Numéros, par File d’appels ou par Utilisateurs,
       pour identifiez les appels perdus, ajuster vos ressources et réduire les
       temps d’attente.
      </p>
      <div
       class="mt-10 flex flex-col sm:flex-row justify-end gap-4 sm:gap-8 max-w-lg text-white leading-7 text-gray-600 lg:max-w-none">
       <button
        @click="this.$refs.sales.show()"
        class="bg-red-600 px-4 py-2 shadow-md rounded-full text-white hover:shadow-lg hover:bg-red-500 font-bold text-sm">
        Demandez l’activation
       </button>
      </div>
     </div>
    </div>
    <div>
     <iframe
      src="https://www.youtube.com/embed/M3xCymv94JE"
      class="w-full h-64 sm:h-96 lg:h-full rounded-xl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
     </iframe>
    </div>
   </div>
  </div>
 </div>
 <SalesContactCard
  ref="sales"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)" />
</template>

<script>
import SalesContactCard from "./SalesContactCard.vue";

export default {
 props: [],
 components: { SalesContactCard },
 data() {
  return {};
 },
 methods: {},
 mounted() {
  this.$emit("setLoading", false);
 },
};
</script>

<style>
/* Add any custom styles if needed */
</style>
